@import "./global.css";

.toggleButton {
  position: fixed;
  z-index: 50;
  bottom: 25px;
  left: 25px;
  width: 120px;
  height: 50px;
  border: 2px solid var(--primary-color);
  background-color: var(--bg-color);
  color: var(--primary-color);
  font-weight: bold;
  border-radius: 10px;
  transition: background-color 1s, color 0.5s, border 0.5s;
}

.toggleButton:hover {
  border-color: var(--font-color);
  color: var(--font-color);
  cursor: pointer;
}

.returnButton:not([data-scroll="0"]) {
  width: 65px;
  height: 65px;
  background-color: var(--primary-color);
  background: linear-gradient(
    var(--primary-color) 100%,
    var(--secondary-color) 0
  );
  border: 3px solid var(--hero-font-color);
  position: fixed;
  z-index: 10;
  bottom: 25px;
  right: 25px;
  border-radius: 50%;
  transition: all 0.25s;
  font-size: 28px;
  font-family: "Times New Roman";
  text-align: center;
  color: var(--hero-font-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.returnButton:hover {
  transform: scale(1.1);
}

.button-text {
  user-select: none;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex-wrap: nowrap;
  transition: transform 0.5s;
}

.button-text#up {
  transform: rotate(180deg);
}
