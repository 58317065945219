@import "./global.css";

:root {
  --card-bg: #fefefe;
}

[data-theme="dark"] {
  --card-bg: #252525;
}

.card {
  width: 500px;
  max-width: 80vw;
  background-color: var(--card-bg);
  border-radius: 15px;
  box-shadow: none;
  transition: box-shadow 0.5s, background-color 0.5s;
}

.card:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.narrow {
  width: 25rem;
}

.info {
  min-width: 15rem;
  width: fit-content;
  max-width: 500px;
  padding: 25px;
  border: 1px solid #898989;
  transition: border 0.5s;
  flex-basis: content;
}

@media only screen and (max-width: 1050px) {
  .info {
    flex-basis: 100%;
  }
}

.info:hover {
  border-color: var(--font-color);
  box-shadow: none;
}

.card-img-container {
  width: 100%;
  height: 16rem;
  overflow: hidden;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  transition: opacity 1s;
}

.card-img-container.work {
  opacity: 0.9;
}

.card:hover .card-img-container.work {
  opacity: 1;
}

.card:hover .card-img-container {
  opacity: 0.6;
}

.card img {
  width: 100%;
  height: 16rem;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  object-fit: fill;
  user-select: none;
  transition: transform 1s;
  transform-origin: 50% 50%;
}

.card:hover img {
  transform: scale(1.05);
  width: 100%;
}

/*
@media only screen and (max-width: 550px) {
  .card img {
    object-fit: fill;
  }
}
*/

.cardContents {
  padding: 15px;
}

.cardTitle {
  font-size: 26px;
}

.info .cardTitle {
  margin-bottom: 5px;
}

.cardSubtitle {
  font-size: 18px;
  font-style: italic;
}

.cardBody {
  font-size: 16px;
}

.info .cardBody {
  text-align: center;
  font-size: 20px;
}

.cardTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.cardTag {
  font-size: 14px;
  background-image: radial-gradient(
    farthest-side at 50vw 100vh,
    var(--secondary-color) 0%,
    var(--primary-color) 100%
  );
  color: var(--hero-font-color);
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  user-select: none;
  transition: opacity 0.5s;
}

.cardTag:hover {
  opacity: 0.85;
}

.cardButtonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 25px;
}

.cardButton {
  width: fit-content;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  border: none;
}

.liveButton {
  background-color: var(--secondary-color);
  color: var(--hero-font-color);
  background-image: url("../img/open.png");
  background-position: -50px 10px;
  background-repeat: no-repeat;
  background-size: 20px;
  transition: opacity 0.5s, padding-left 0.5s, background-position 0.5s;
}

.liveButton:hover {
  opacity: 0.75;
  padding-left: 35px;
  background-position: 8px 10px;
}

:root {
  --github: url("../img/github-light.png");
}

[data-theme="dark"] {
  --github: url("../img/github-dark.png");
}

.sourceButton {
  background-color: transparent;
  background-image: var(--github);
  background-position: -50px 7px;
  background-repeat: no-repeat;
  background-size: 25px;
  color: var(--font-color);
  border: 1px solid var(--font-color);
  transition: background-color 0.5s, color 0.5s, padding-left 0.5s,
    background-position 0.5s;
}

.sourceButton:hover {
  background-color: var(--font-color);
  color: var(--bg-color);
  padding-left: 45px;
  background-position: 10px 7px;
}
