@import url("https://fonts.googleapis.com/css?family=Nunito");

/*
  Other color schemes I like:
    primary: rgb(114, 73, 200), Secondary: rgb(78, -109, 178)   // purple
    primary: rgb(32, 145, 246), Secondary: rgb(-4, -37, 224)    // blue
    Primary: rgb(90, 40, 200), Secondary: rgb(54, -142, 178)    // purple 2
*/

:root {
  --primary-color: var(--true-primary-color);
  --secondary-color: var(--true-secondary-color);
  --true-primary-color: rgb(25, 121, 97);
  --true-secondary-color: #1241dc;
  --bg-color: #eee;
  --hero-font-color: #eee;
  --font-color: #1f1f1f;
  --bg-opacity: 1;
}

[data-theme="dark"] {
  --bg-color: #1f1f1f;
  --font-color: #eee;
  --bg-opacity: 0.5;
}

body {
  margin: 0;
  scroll-snap-stop: always;
}

.content {
  background-image: radial-gradient(
    farthest-side at 50vw 100vh,
    var(--secondary-color) 0%,
    var(--primary-color) 100%
  );
  background-size: 100vw 100vh;
  background-attachment: fixed;
  transition: background-color 0.5s, color 0s;
  color: var(--font-color);
  font-family: Nunito;
  margin: 0;
  overflow-x: hidden;
  scroll-snap-stop: always;
}

.link {
  background: linear-gradient(
    45deg,
    var(--secondary-color),
    var(--primary-color)
  );
  background-clip: text;
  color: transparent;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--secondary-color);
  transition: color 1s;
  border: none;
  font-size: 24px;
  font-weight: normal;
  padding: 0;
}

.link:hover {
  color: var(--primary-color);
}

hr {
  width: 50vw;
  margin-top: 50px;
  margin-bottom: 25px;
}
