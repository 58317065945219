@import "./global.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.section {
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  transition: all 0.5s;
  background-color: var(--bg-color);
}

.section-header {
  font-size: 68px;
  user-select: none;
  color: var(--hero-font-color);
}

.section-header.gradient {
  font-size: 68px;
  user-select: none;
  background: linear-gradient(
    -45deg,
    var(--secondary-color) 0,
    var(--primary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.section-body {
  font-size: 24px;
  padding-top: 25px;
  padding-bottom: 50px;
  margin: auto;
}

.section.gradient {
  background-color: transparent;
}

.card-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: space-evenly;
  width: 100%;
}

form {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex;
  align-items: center;
}

input,
textarea {
  padding: 15px;
  border-radius: 5px;
  width: 50vw;
  font-size: 14px;
  font-family: Nunito;
  background-color: var(--card-bg);
  border: 1px solid #898989;
  color: var(--font-color);
  transition: border 0.5s, background-color 0.5s;
}

input:hover,
textarea:hover {
  border-color: var(--font-color);
}

::placeholder {
  color: var(--font-color);
}

textarea {
  min-width: 50vw;
  max-width: 50vw;
  min-height: 50px;
  font-family: Nunito;
}

.submit {
  width: 100%;
  transition: background-color 0.5s, color 0.5s, font-weight 0.5s;
}

.submit:hover {
  background-color: var(--font-color);
  color: var(--bg-color);
  font-weight: bold;
}

#footer {
  background-color: var(--secondary-color);
  opacity: var(--bg-opacity);
  padding: 50px;
  padding-left: 200px;
  padding-right: 200px;
  color: var(--hero-font-color);
  height: fit-content;
}
