.navbar {
  display: flex block;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  color: var(--hero-font-color);
  user-select: none;
  z-index: 25;
}

.nav-container {
  margin: 5px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.nav-item {
  margin: 5px 10px;
}

.nav-title {
  margin: 5px 20px;
  font-size: 24px;
  transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav-title:hover {
  transform: rotate(1080deg);
}

.nav-link {
  border: 1px solid transparent;
  transition: border-bottom 1s, color 1s;
  background-color: transparent;
  color: var(--hero-font-color);
  font-size: 16px;
  transition: all 0.5s;
}

.nav-link:hover {
  border-bottom-color: white;
  cursor: pointer;
  color: white;
  padding-bottom: 5px;
}

.nav-button {
  border: 1px solid var(--hero-font-color);
  background-color: transparent;
  color: var(--hero-font-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 18px;
  border-radius: 50%;
  transition: background-color 0.5s, color 0.5s;
}

.nav-button:hover {
  background-color: var(--hero-font-color);
  color: var(--primary-color);
  cursor: pointer;
}

#anchors {
  justify-content: center;
  width: fit-content;
  flex-wrap: nowrap;
}

#title,
#socials {
  flex-basis: 100%;
}

#socials {
  justify-content: flex-end;
}

.burger {
  border-radius: 5px;
}

.close {
  color: var(--primary-color);
  border: none;
  display: none;
  transition: transform 0.5s;
}

.close:hover {
  transform: scale(1.25);
  transform-origin: center;
}

#burger {
  display: none;
}

@media only screen and (max-width: 1050px) {
  #anchors {
    display: none;
  }
  #burger {
    display: block;
  }
}

.expanded .close {
  display: block;
  position: fixed;
  font-size: 48px;
  top: 25px;
  right: 25px;
  background-color: var(--bg-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.expanded #anchors {
  animation: fadeIn 1s;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color);
  position: fixed;
  top: -5px;
  left: -20px;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.expanded .nav-link {
  font-size: 68px;
  user-select: none;
  background: linear-gradient(
    -45deg,
    var(--secondary-color) 0,
    var(--primary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 1px transparent;
  padding-bottom: 5px;
  font-size: 48px;
}

.expanded .nav-link:hover {
  transform: scale(1.1);
}
